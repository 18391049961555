import React from 'react';

import classes from './Bar.module.css';

const Bar = (props) => {
  let classNames = [classes.Bar];
  let barClass;
  switch (props.weight) {
    case 45:
      barClass = classes['Lbs45'];
      break;
    case 15:
      barClass = classes['Kg15'];
      break;
    case 20:
      barClass = classes['Kg20'];
      break;
    case 25:
      barClass = classes['Kg25'];
      break;
    default:
      alert(`That's a wrong bar weight`);
      break;
  }
  return (
    <div className={classNames.join(' ')}>
      <div className={classes.Knurling + ' ' + barClass}></div>
      <div className={classes.Collar + ' ' + barClass}></div>
      <div className={classes.Sleeve + ' ' + barClass}> {props.weight}</div>
    </div>
  );
};

export default Bar;
