import React from 'react';

import classes from './BlankPlates.module.css';

const blankPlates = (props) => {
  return (
    <div className={classes.BlankPlates}>
      <div className={classes.BlanksCentered}>
        <div>How much weight are you putting on the bar?</div>
        <div className={classes.SmallDisclaimer}>(Make sure it's more than the bar weighs)</div>
      </div>
    </div>
  );
};

export default blankPlates;
