import React from 'react';

import classes from './WeightInput.module.css';

const WeightInput = (props) => {
  return (
    <React.Fragment>
      <div className={classes.WeightInputDiv}>
        <input className={classes.WeightInput} onChange={props.changed} value={props.value} />
        <label className={classes.WeightLabel}>{props.weightType}</label>
      </div>
    </React.Fragment>
  );
};

export default WeightInput;
