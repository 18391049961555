import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Button from '../../components/UI/Button/Button';
import WeightInput from '../WeightInput/WeightInput';
import Bar from '../../components/Weights/Bar/Bar';
import Plate from '../../components/Weights/Plate/Plate';
import BlankPlates from '../../components/UI/PlateCalculatorSupport/BlankPlates';
import classes from './PlateCalculator.module.css';

class PlateCalculator extends Component {
  state = {
    pounds: 0,
    kilos: 0,
    barWeight: 45,
    poundPlatePairs: {
      2.5: 0,
      5: 0,
      10: 0,
      25: 0,
      35: 0,
      45: 0,
    },
    kiloPlatePairs: {
      0.5: 0,
      1: 0,
      1.5: 0,
      2.5: 0,
      5: 0,
      10: 0,
      15: 0,
      20: 0,
      25: 0,
    },
    usePounds: true,
  };

  PoundsToKilosRatio = 2.2;

  consoleOutState = () => {
    console.log(this.state);
  };

  handleChangePounds = async (event) => {
    let nLbsValue = Number(event.target.value);
    // magic number 1001 from testing screen width
    if ((nLbsValue > 0 && nLbsValue < 1001) || nLbsValue === 0) {
      let sDisplayedLbs;
      ({ sDisplayedString: sDisplayedLbs, nSanitizedValue: nLbsValue } = this.sanitizeDecimal(event, nLbsValue));
      let nNewKilos = nLbsValue / this.PoundsToKilosRatio;
      nNewKilos = nNewKilos.toFixed(1);
      await this.setState({ pounds: sDisplayedLbs, kilos: Number(nNewKilos) });
      await this.countPlatePairs('poundPlatePairs');
      this.countPlatePairs('kiloPlatePairs');
    }
  };

  handleChangeKilos = async (event) => {
    let nKilosValue = Number(event.target.value);
    // magic number 454.5 from testing screen width
    if ((nKilosValue > 0 && nKilosValue < 454.5) || nKilosValue === 0) {
      let sDisplayedKilos;
      ({ sDisplayedString: sDisplayedKilos, nSanitizedValue: nKilosValue } = this.sanitizeDecimal(event, nKilosValue));
      let nNewLbs = nKilosValue * this.PoundsToKilosRatio;
      nNewLbs = nNewLbs.toFixed(1);
      await this.setState({ pounds: Number(nNewLbs), kilos: sDisplayedKilos });
      await this.countPlatePairs('kiloPlatePairs');
      this.countPlatePairs('poundPlatePairs');
    }
  };

  sanitizeDecimal(event, nSanitizedValue) {
    let sDisplayedString = event.target.value;
    let arrSplitString = event.target.value.split('.');
    if (arrSplitString.length > 1 && arrSplitString[1].length > 1) {
      sDisplayedString = arrSplitString[0] + '.' + arrSplitString[1][0]; // first number after the decimal
      nSanitizedValue = Number(sDisplayedString);
    }
    return { sDisplayedString, nSanitizedValue };
  }

  countPlatePairs = async (stateKey) => {
    let numKeys = this.getWeightKeysInOrder(stateKey);

    // loop through all keys
    let remainder = stateKey === 'poundPlatePairs' ? this.state.pounds : this.state.kilos;
    // get barweight
    let barWeight = this.getBarWeightInUnits(stateKey);
    remainder -= barWeight;

    let objNewState = {};
    for (let numPlate of numKeys) {
      // calculate plate count per key
      let numPlatePairCount = this.calculateWeightPairCount(remainder, numPlate);

      if (remainder < 0) {
        objNewState[numPlate] = 0;
      } else {
        objNewState[numPlate] = numPlatePairCount;
        // after you know the count, do math to find the remainder
        remainder -= 2 * numPlatePairCount * numPlate;
        // send remainder to the next loop
      }
    }

    // push all calculated values into the new state
    let newState = { ...this.state };
    newState[stateKey] = objNewState;
    this.setState(newState);
  };

  getBarWeightInUnits(stateKey) {
    let barWeight = this.state.barWeight;
    // if poundplatepairs convert to pounds
    if (stateKey === 'poundPlatePairs') {
      // bar less than 45 is a kilos bar, need to convert
      barWeight = barWeight < 45 ? barWeight * 2.2 : barWeight;
    } else {
      // bar weight 45 or more, convert to kilos
      barWeight = barWeight >= 45 ? Math.floor(barWeight / 2.2) : barWeight;
    }
    return barWeight;
  }

  calculateWeightPairCount(nPoundNumber, nPlateWeight) {
    // magic 2 because we're counting plate pairs not individual plates
    return Math.floor(nPoundNumber / (nPlateWeight * 2));
  }

  getWeightKeysInOrder(stateKey) {
    let objPlatePairs = { ...this.state[stateKey] };
    // convert keys to num so we can order them
    let sKeys = Object.keys(objPlatePairs);
    let numKeys = [];
    sKeys.map((sKey) => {
      numKeys.push(Number(sKey));
    });
    // sort from largest to smallest
    numKeys.sort((a, b) => {
      return b - a;
    });
    return numKeys;
  }

  toggleDisplayWeight = async () => {
    let newUsePounds = !this.state.usePounds;
    await this.setState({ usePounds: newUsePounds });
    this.countPlatePairs(newUsePounds ? 'poundPlatePairs' : 'kiloPlatePairs');
  };

  setBarWeight = async (weight) => {
    await this.setState({ barWeight: weight });
    this.countPlatePairs(this.state.usePounds ? 'poundPlatePairs' : 'kiloPlatePairs');
  };

  render() {
    let stateKey = this.state.usePounds ? 'poundPlatePairs' : 'kiloPlatePairs';
    let objPoundPlatePairs = this.state.usePounds ? this.state.poundPlatePairs : this.state.kiloPlatePairs;
    let weightArray = this.getWeightKeysInOrder(stateKey);

    // Build the plates
    let bShowBar = false;
    let numDisplayedWeight = 0;
    let plateImages = weightArray.map((element) => {
      if (objPoundPlatePairs[element] !== 0) {
        let platesAtThisWeight = [];
        for (let index = 0; index < objPoundPlatePairs[element]; index++) {
          platesAtThisWeight.push(
            <Plate
              key={element + index}
              plateWeight={element}
              units={this.state.usePounds ? 'Lbs' : 'Kg'}
              plateCount={objPoundPlatePairs[element]}
            />
          );
          bShowBar = true;
          numDisplayedWeight += element * 2;
        }
        return platesAtThisWeight;
      }
    });
    // add bar weight to display weight
    if (this.state.usePounds) {
      if (this.state.barWeight === 45) {
        numDisplayedWeight += this.state.barWeight;
      } else {
        numDisplayedWeight += this.state.barWeight * this.PoundsToKilosRatio;
      }
    } else if (!this.state.usePounds) {
      if (this.state.barWeight === 45) {
        numDisplayedWeight += this.state.barWeight / this.PoundsToKilosRatio;
      } else {
        numDisplayedWeight += this.state.barWeight;
      }
    }

    if (String(numDisplayedWeight).split('.').length > 1) {
      numDisplayedWeight = numDisplayedWeight.toFixed(1);
    }
    // create bar selection buttons
    let weightBars = [45, 15, 20, 25];
    let weightBarButtons = weightBars.map((element) => {
      return (
        <Button
          key={element}
          btnType="Bar"
          disabled={true}
          clicked={() => {
            this.setBarWeight(element);
          }}
          active={this.state.barWeight === element}
          className={classes.Button}
        >{`${element} ${element < 45 ? 'kg' : 'lbs'}`}</Button>
      );
    });

    return (
      <div className={classes.PlateCalculator}>
        <div className={classes.NavBar}>My Friend Gym</div>
        <div className={classes.WeightInputContainer}>
          <WeightInput
            weightType={'Pounds'}
            changed={this.handleChangePounds}
            value={this.state.pounds === 0 ? '' : this.state.pounds}
          />
          <WeightInput
            weightType={'Kilos'}
            changed={this.handleChangeKilos}
            value={this.state.kilos === 0 ? '' : this.state.kilos}
          />
        </div>
        <div className={classes.WeightDisplay}>
          {bShowBar ? <Bar weight={this.state.barWeight} /> : null}
          {bShowBar ? <div className={classes.PlateContainer}>{plateImages}</div> : <BlankPlates />}
        </div>
        {bShowBar ? (
          <div className={classes.DisplayedWeight}>
            Displayed Weight: {numDisplayedWeight} {this.state.usePounds ? 'Lbs' : 'Kg'}
          </div>
        ) : null}
        <label className={classes.BarLabel}> Bar Selection </label>
        <div className={classes.ButtonGridContainer}>{weightBarButtons}</div>
        <Button btnType={'ToggleUnits'} disabled={false} clicked={this.toggleDisplayWeight}>
          {this.state.usePounds ? 'Kilo Plates' : 'Pound Plates'}
        </Button>
      </div>
    );
  }
}

export default PlateCalculator;
