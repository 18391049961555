import React from 'react';

import classes from './Button.module.css';

const button = (props) => {
  let buttonClasses = [classes.Button, classes[props.btnType]];
  if (props.active) {
    buttonClasses.push(classes.Active);
  }
  return (
    <button onClick={props.clicked} className={buttonClasses.join(' ')}>
      {props.children}
    </button>
  );
};

export default button;
