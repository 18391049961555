import logo from './logo.svg';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PlateCalculator from './containers/PlateCalculator/PlateCalculator';
import Spinner from './components/UI/Spinner/Spinner';
import classes from './App.module.css';

class App extends Component {
  render() {
    return (
      <div className={classes.App}>
        <Switch>
          <Route path="/test" component={Spinner} />
          <Route path="/" exact component={PlateCalculator} />
        </Switch>
      </div>
    );
  }
}

export default App;
