import React from 'react';

import classes from './Plate.module.css';

const Plate = (props) => {
  let cssWeight = props.plateWeight;
  if (props.plateWeight === 1.25) {
    cssWeight = 'OnePointTwoFive';
  } else if (props.plateWeight === 2.5) {
    cssWeight = 'TwoPointFive';
  } else if (props.plateWeight === 1.5) {
    cssWeight = 'OnePointFive';
  } else if (props.plateWeight === 0.5) {
    cssWeight = 'PointFive';
  }
  let divClasses = [classes.Plate, classes[props.units + cssWeight]].join(' ');

  return <div className={divClasses}>{props.plateWeight}</div>;
};

export default Plate;
